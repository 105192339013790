<template>
  <validation-observer
    ref="studentAwardSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="studentAwardSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm SV đạt khen thưởng' : 'Cập nhật SV đạt khen thưởng'"
      size="lg"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Hệ đào tạo">
              <v-select
                v-model="targetData.trainingSystemId"
                :disabled="!isCreated"
                :options="trainingSystems"
                :reduce="option => option.value"
                :clearable=false
                @input="onTrainingSystemsChange"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="Khóa học"
            >
              <v-select
                v-model="targetData.courseId"
                :disabled="!isCreated"
                :options="courses"
                :reduce="option => option.value"
                @input="onCoursesChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Học kỳ"
          label-for="courseSemester_id"
        >
          <v-select
            :disabled="!isCreated"
            v-model="targetData.courseSemesterId"
            :options="courseSemesters"
            :reduce="option => option.value"
          />
        </b-form-group>
        <b-form-group
          label="Khoa"
          label-for="departmentId"
        >
          <v-select
            :disabled="!isCreated"
            v-model="targetData.departmentId"
            :options="departments"
            :reduce="option => option.value"
            placeholder="Khoa/Bộ môn"
            @input="onDepartmentChange"
          />
        </b-form-group>
        <b-form-group
          label="Chuyên ngành"
          label-for="majorId"
        >
          <v-select
            :disabled="!isCreated"
            v-model="targetData.majorId"
            :options="majors"
            :reduce="option => option.value"
            placeholder="Chuyên ngành"
            @input="onMajorChange"
          />
        </b-form-group>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Lớp cố định" label-for="classId">
              <v-select
                id="classId"
                :disabled="!isCreated"
                v-model="targetData.classId"
                :options="classes"
                :reduce="option => option.value"
                @input="onClassChange"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label-for="studentId">
              <template v-slot:label>
                Sinh viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Sinh viên"
                rules="required"
              >
                <v-select
                  v-model="targetData.studentId"
                  :disabled="!isCreated"
                  :options="studentsCourse"
                  :reduce="option => option.id"
                  label="name"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="awardId">
          <template v-slot:label>
            Hình thức khen thưởng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hình thức khen thưởng"
            rules="required"
          >
            <v-select
              v-model="targetData.awardId"
              :options="awardOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('studentAwardSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BCol, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'StudentAwardSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        organizationId: getUser().orgId,
        departmentId: null,
        majorId: null,
        classId: null,
        awardId: null,
        studentId: null,
        status: 1,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
      },
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
      required,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      studentsCourse: 'student/students',
      factAwards: 'factAward/dataSources',
    }),
    isCreated() {
      return !this.dataSource
    },
    awardOptions() {
      return this.factAwards.map(item => ({ value: item.id, label: item.name }))
    },
  },

  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setCourseSemester: 'dropdown/SET_COURSE_SEMESTERS',
      setMajors: 'dropdown/SET_MAJORS',
      setClasses: 'dropdown/SET_CLASSES',
      setStudents: 'student/SET_STUDENTS',
    }),
    ...mapActions({
      createStudentAward: 'studentAward/createStudentAward',
      updateStudentAward: 'studentAward/updateStudentAward',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      getStudentsByCourse: 'student/getStudentsByCourse',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.trainingSystems.length === 0) {
        await this.getTrainingSystems({ organizationId: getUser().orgId })
      }
      if (this.isCreated) {
        this.targetData.trainingSystemId = this.trainingSystems[0].value
        this.targetData.departmentId = this.departments[0].value
      } else {
        this.targetData.trainingSystemId = this.dataSource.trainingSystemId
        this.targetData.departmentId = this.dataSource.departmentId
      }

      await Promise.all([
        await this.getCourses({
          organizationId: getUser().orgId,
          trainingSystemId: this.targetData.trainingSystemId,
        }),
        await this.getMajors({
          organizationId: getUser().orgId,
          departmentId: this.targetData.departmentId,
        }),
      ])
      if (!this.isCreated && this.dataSource) {
        this.targetData = { ...this.dataSource }
        await Promise.all([
          await this.getClasses({
            organizationId: getUser().orgId,
            trainingSystemId: this.targetData.trainingSystemId,
            courseId: this.targetData.courseId,
            departmentId: this.targetData.departmentId,
            majorId: this.targetData.majorId,
          }),
          await this.getStudentsByCourse({
            organizationId: getUser().orgId,
            trainingSystemId: this.targetData.trainingSystemId,
            courseId: this.targetData.courseId,
            departmentId: this.targetData.departmentId,
            majorId: this.targetData.majorId,
            classId: this.targetData.classId,
            currentPage: 1,
            itemsPerPage: 100,
          }),
        ])
      }
    },
    onHide() {
      this.targetData = {
        organizationId: getUser().orgId,
        departmentId: null,
        majorId: null,
        classId: null,
        awardId: null,
        studentId: null,
        status: 1,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .studentAwardSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createStudentAward(this.targetData)
            : await this.updateStudentAward(this.targetData)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('studentAwardSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async onTrainingSystemsChange(event) {
      this.setCourses([])
      this.targetData.courseId = null

      await this.getCourses({
        organizationId: getUser().orgId,
        trainingSystemId: event,
      })
      this.targetData.classId = null
    },
    async onCoursesChange(event) {
      this.setMajors([])
      this.targetData.courseSemesterId = null

      await this.getCourseSemesters({
        organizationId: getUser().orgId,
        courseId: event,
      })
      this.targetData.classId = null
    },
    async onDepartmentChange(event) {
      this.setMajors([])
      this.targetData.majorId = null

      await this.getMajors({
        organizationId: getUser().orgId,
        departmentId: event,
      })
      this.targetData.classId = null
    },
    async onMajorChange(event) {
      this.setClasses([])
      await this.getClasses({
        organizationId: getUser().orgId,
        courseId: this.targetData.courseId,
        departmentId: this.targetData.departmentId,
        majorId: event,
      })
      this.targetData.classId = null
    },
    async onClassChange(event) {
      this.setStudents([])
      await this.getStudentsByCourse({
        organizationId: getUser().orgId,
        courseId: this.targetData.courseId,
        departmentId: this.targetData.departmentId,
        majorId: this.targetData.majorId,
        classId: event,
        currentPage: 1,
        itemsPerPage: 100,
      })
    },
  },
}
</script>
